
/* eslint-disable no-useless-escape */
const Development = false;
const Constant = {
    REGEX: {
        EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        SPECIALCHARACTERS: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        UPPERCASE: /.*[A-Z]|[A-Z]/,
        LOWERCASE: /.*[a-z]|[a-z]/,
        NUMBER: /[0-9]/,
        NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
        ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,
        ALPHABETIC: /^[A-Za-z ]+$/,
        ACCENTEDALPHABETIC: /^[A-zÀ-ú- ]+$/,
        WEBSITEURL: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/,
        URL:/^(https?:\/\/(www\.)?)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
        PHONENUMBER: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
        NOSPECIALCHARACTERS: /^[A-Za-z0-9 ]+$/,
        MIN_MAX_PASSWORD: /^(?=).{8,16}$/,
        MIN_10_NUMBER: /^([0-9]){10,}$/,
        BUSINESSOWNERNAME: /^[A-Za-z\ \.\,\_\-]+$/
    },
    BASE_URL : Development ? "https://devapi.transformationalrunners.com" : "https://api.transformationalrunners.com"
};
export default Constant;
